import { forwardRef, SVGProps } from 'react';

type Props = { active?: boolean; disabled?: boolean } & SVGProps<SVGSVGElement>;

import { palette } from "theme";

const BasicRadioButton = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { active, disabled, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M12 19a7 7 0 1 0 0-14 7 7 0 0 0 0 14z"
        stroke={disabled ? 'gray' : '#333'}
        strokeWidth={0.75}
        strokeMiterlimit={10}
      />
      {active && (
        <circle cx={12} cy={12} r={4} fill={disabled ? 'gray' : palette.primary.main} />
      )}
    </svg>
  );
});

BasicRadioButton.defaultProps = {
  active: false,
  disabled: false,
};

BasicRadioButton.displayName = 'BasicRadioButton';

export default BasicRadioButton;
